define('saves-client/pods/normal/orgs-status/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    sortedOrganisations: Ember.computed.sort('model', 'orgSorting'),
    filter: '',
    orgSorting: ['fullname'],
    upload_warning: 14,
    filteredOrganisations: Ember.computed.filter('sortedOrganisations', function (org) {
      let name = org.get('fullname').toLowerCase();
      return name.indexOf(this.get('filter').toLowerCase()) >= 0;
    }).property('filter', 'sortedOrganisations.@each.fullname')
  });
});