define('saves-client/pods/components/tip-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    account: Ember.inject.service(),
    store: Ember.inject.service(),
    tagName: "ul",
    classNames: ["itemlist"],
    organisation: null,
    newTitle: null,
    newBody: null,
    canCreate: Ember.computed('newTitle', 'newBody', function () {
      let title = this.get('newTitle');
      let body = this.get('newBody');
      return !!title && !!body;
    }),
    actions: {
      create() {
        let title = this.get('newTitle');
        let body = this.get('newBody');
        let org = this.get('organisation');
        let new_tip = this.get('store').createRecord('tip', {
          title: title,
          body: body,
          orgname: org
        });
        new_tip.save().then(() => {
          this.set('newTitle', null);
          this.set('newBody', null);
        });
      }
    }
  });
});