define('saves-client/pods/normal/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    account: Ember.inject.service(),
    sortProperties: ['published:desc', 'fullname'],
    sortedOrganisations: Ember.computed.sort('model', 'sortProperties'),
    filteredOrganisations: Ember.computed('sortedOrganisations', 'filter', 'showDisabled', function () {
      let orgs = this.get('sortedOrganisations');
      let filter = this.get('filter');
      let show_disabled = this.get('showDisabled');
      return orgs.filter(org => {
        let name = org.get('fullname').toLowerCase();
        let disabled = org.get('disabled');
        if (disabled && !show_disabled) return false;
        return !filter || name.indexOf(filter.toLowerCase()) >= 0;
      });
    }),
    showDisabled: false,
    filter: ""
  });
});