define('saves-client/pods/components/modal-background/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "div",
    classNames: ['modal'],
    click() {
      this.sendAction('closed');
    }
  });
});