define('saves-client/models/file', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    orgname: _emberData.default.belongsTo('organisation'),
    basename: _emberData.default.attr('string'),
    extension: _emberData.default.attr('string'),
    converted: _emberData.default.attr('boolean'),
    imported: _emberData.default.attr('boolean'),
    problem: _emberData.default.attr('boolean'),
    message: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    reset: _emberData.default.attr('boolean', { defaultvalue: false }),
    created_at: _emberData.default.attr('isodate'),
    filename: function () {
      return this.get('basename') + this.get('extension');
    }.property('basename', 'extension'),
    icon: function () {
      if (this.get('problem')) {
        return "exclamation-triangle";
      }
      if (!this.get('converted')) {
        return "cog";
      }
      if (!this.get('imported')) {
        return "cog";
      }
      return "check";
    }.property('converted', 'imported', 'problem'),
    complete: function () {
      return this.get('converted') && this.get('imported');
    }.property('converted', 'imported')
  });
});