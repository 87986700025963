define('saves-client/pods/normal/organisation/data/entity/device/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    measurementsByMonth: function () {
      var result = Ember.A([]);
      this.get('model.measurements').forEach(function (measurement) {
        var month = measurement.get('month');
        var monthGroup = result.find(function (element) {
          return element.get('month').format("MMYYYY") === month.format("MMYYYY");
        });
        if (!monthGroup) {
          monthGroup = Ember.Object.create({
            month: month,
            contents: []
          });
          result.pushObject(monthGroup);
        }
        monthGroup.get('contents').pushObject(measurement);
      });
      return result.sort(function (a, b) {
        return a.get('month') - b.get('month');
      });
    }.property('model.measurements.[]')
  });
});