define('saves-client/pods/components/competition-item/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['runState', 'published::unpublished'],
    attributeBindings: ['draggable'],
    competition: null,
    route: null,
    isAllowed: false,
    draggable: false,
    published: Ember.computed.alias('competition.published'),
    organisation: Ember.computed.alias('competition.organisation'),
    dragStart: function (event) {
      event.dataTransfer.setData('text/data', this.get('competition.id'));
    },
    note: function () {
      var name = this.get('competition.name');
      if (this.get('competition.isCurrent')) {
        return name + " is live! Ends " + (0, _moment.default)().to(this.get('competition.end_date'));
      }
      if (this.get('competition.inPast')) {
        return name + " finished " + (0, _moment.default)().to(this.get('competition.end_date'));
      }
      if (this.get('competition.inFuture')) {
        return name + " has not started yet! Starts " + (0, _moment.default)().to(this.get('competition.start_date'));
      }
      return name;
    }.property('competition.isCurrent', 'competition.inPast', 'competition.inFuture', 'competition.end_date', 'competition.start_date'),
    icon: function () {
      if (this.get('competition.isCurrent')) {
        return "dashboard";
      }
      if (this.get('competition.inPast')) {
        return "trophy";
      }
      if (this.get('competition.inFuture')) {
        return "gears";
      }
      return "exclamation-circle";
    }.property('competition.isCurrent', 'competition.inPast', 'competition.inFuture'),
    runState: function () {
      if (this.get('competition.isCurrent')) {
        return "live";
      }
      if (this.get('competition.inPast')) {
        return "past";
      }
      if (this.get('competition.inFuture')) {
        return "future";
      }
    }.property('competition.isCurrent', 'competition.inPast', 'competition.inFuture'),
    publishedIcon: function () {
      return this.get("competition.published") ? "toggle-on" : "toggle-off";
    }.property("competition.published"),
    publishedTitle: function () {
      return this.get("competition.published") ? "Click to hide" : "Click to show";
    }.property("competition.published"),
    actions: {
      setMainCompetition: function () {
        var competition = this.get('competition');
        this.get('organisation.competitions').forEach(function (comp) {
          comp.set('main', false);
        });
        competition.set('main', true);
        competition.save();
        var organisation = this.get('organisation');
        organisation.set("main_competition", competition);
      },
      delete: function () {
        var competition = this.get('competition');
        if (confirm("Are you sure you want to remove competition '" + competition.get('name') + "'?")) {
          competition.destroyRecord();
        }
      },
      togglePublished: function () {
        var competition = this.get('competition');
        competition.toggleProperty("published");
        competition.save();
      }
    }
  });
});