define('saves-client/models/performance', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    competitor: _emberData.default.belongsTo('competitor'),
    start_date: _emberData.default.attr('isodate'),
    end_date: _emberData.default.attr('isodate'),
    target: _emberData.default.attr('number'),
    actual: _emberData.default.attr('number'),
    adjustment: _emberData.default.attr('number'),
    adjusted_target: function () {
      return this.get('target') + parseFloat(this.get('adjustment'));
    }.property('target', 'adjustment'),
    savings: function () {
      var target = this.get('adjusted_target');
      var actual = this.get('actual');
      if (target && actual) {
        return ((target - actual) / target * 100).toFixed(1);
      } else {
        return null;
      }
    }.property('adjusted_target', 'actual'),
    include_in_cumulative: function () {
      var actual = this.get('actual');
      return !!actual;
    }.property('actual')
  });
});