define('saves-client/pods/normal/international/new/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function () {
      // TODO: set magic default values based on current date?
      return this.store.createRecord('international_competition');
    },
    actions: {
      willTransition: function (transition) {
        var model = this.controller.get('model');
        if (model.get('hasDirtyAttributes')) {
          if (!model.get('name') || confirm("Discard new competition '" + model.get('name') + "'?")) {
            model.deleteRecord();
            return true;
          } else {
            transition.abort();
          }
        } else {
          return true;
        }
      }
    }
  });
});