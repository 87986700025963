define('saves-client/pods/normal/organisation/competitions/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    organisation: Ember.inject.controller('normal.organisation'),
    advanced: false,
    defaultModel: 'monthly',
    sortProperties: ['isCurrent:desc', 'end_date'],
    sortedCompetitions: Ember.computed.sort('model', 'sortProperties'),
    buttonDisabled: Ember.computed('canCreate', function () {
      return !this.get('canCreate');
    }),
    selectedCompetition: null,
    compPeriodValid: Ember.computed('compstart', 'compend', function () {
      var compstart = this.get('compstart');
      var compend = this.get('compend');
      return !Ember.isEmpty(compstart) && !Ember.isEmpty(compend) && compstart < compend;
    }),
    basePeriodValid: Ember.computed('basestart', 'baseend', function () {
      var basestart = this.get('basestart');
      var baseend = this.get('baseend');
      return !Ember.isEmpty(basestart) && !Ember.isEmpty(baseend) && basestart < baseend;
    }),
    periodsValid: Ember.computed('compstart', 'baseend', function () {
      var compstart = this.get('compstart');
      var baseend = this.get('baseend');
      return !Ember.isEmpty(compstart) && !Ember.isEmpty(baseend) && compstart > baseend;
    }),
    canCreate: Ember.computed('newName', 'compPeriodValid', 'basePeriodValid', 'periodsValid', function () {
      var newName = this.get('newName');
      return !Ember.isEmpty(newName) && this.get('compPeriodValid') && this.get('basePeriodValid') && this.get('periodsValid');
    }),
    actions: {
      setMainCompetition: function (competition) {
        this.get('organisation').send('setMainCompetition', competition);
      },
      createComp: function () {
        var organisation = this.get('organisation.model');
        var newName = this.get('newName');
        var compstart = new Date(Date.parse(this.get('compstart')));
        var compend = new Date(Date.parse(this.get('compend')));
        var basestart = new Date(Date.parse(this.get('basestart')));
        var baseend = new Date(Date.parse(this.get('baseend')));
        var new_competition = this.store.createRecord('competition', {
          name: newName,
          organisation: organisation,
          start_date: compstart,
          end_date: compend,
          default_baseline_model: this.get('defaultModel'),
          default_baseline_start: basestart,
          default_baseline_end: baseend
        });
        organisation.get('competitions').pushObject(new_competition);
        var self = this;
        new_competition.save().then(function () {
          self.set('newName', null);
          self.set('advanced', false);
          self.set('compstart', null);
          self.set('compend', null);
          self.set('defaultModel', 'monthly');
          self.set('basestart', null);
          self.set('baseend', null);
        });
      },
      toggleAdvanced() {
        this.toggleProperty('advanced');
      },
      setModel(model) {
        this.set('defaultModel', model);
      }
    }
  });
});