define("saves-client/pods/components/aggregate-savings/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "aside",
    prefix: "Saved",
    classNames: ['aggregate-savings'],
    classNameBindings: ['badge', 'iframe'],
    badge: false,
    competition: null
  });
});