define('saves-client/pods/normal/organisation/competition/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function (params) {
      return this.store.findRecord('competition', params.competition_id);
    }
  });
});