define('saves-client/pods/components/org-summary-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "table",
    orgs: null,
    customer_groups: null,
    upload_warning: 7,
    editing_org: null,
    actions: {
      edit_org(org) {
        let self = this;
        org.get('organisation').then(function (org) {
          self.set('editing_org', org);
        });
      },
      clear_org() {
        this.set('editing_org', null);
      }
    }
  });
});