define('saves-client/pods/components/tip-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const re = new RegExp("^$|(https|http)://.*");

  exports.default = Ember.Component.extend({
    tagName: 'li',
    // attributeBindings: ['title'],
    // title: computed.alias('tip.body'),
    tip: null,
    editing: false,
    valid_url: Ember.computed('tip.url', function () {
      let url = this.get('tip.url');
      return re.test(url) ? true : !url;
    }),
    invalid_url: Ember.computed.not('valid_url'),
    show_url_error: Ember.computed.and('invalid_url', 'tip.url'),
    show_save_button: Ember.computed.and('valid_url', 'tip.body'),
    actions: {
      toggleEditable() {
        this.toggleProperty('editing');
        if (!this.get('editing')) {
          this.get('tip').rollbackAttributes();
        }
      },
      save() {
        let self = this;
        this.get('tip').save().then(function () {
          self.set('editing', false);
        });
      },
      delete() {
        let tip = this.get('tip');
        if (confirm("Are you sure you want to delete this tip?")) {
          tip.destroyRecord();
        }
      }
    }
  });
});