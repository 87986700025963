define('saves-client/pods/components/international-registrant/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ["is_my_competition:highlight"],
    registrant: null,
    organisation: null,
    _organisation: function () {
      return this.get('registrant') && this.get('registrant').get('organisation');
    }.property('registrant.organisation'),
    is_my_competition: function () {
      return this.get('_organisation.id') === this.get('organisation.id');
    }.property('_organisation.isFullfilled'),
    actions: {
      delete: function () {
        this.get('registrant').destroyRecord();
      }
    }
  });
});