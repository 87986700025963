define('saves-client/pods/normal/users/user/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    editing: false,
    password_check: null,
    password_provided: Ember.computed.bool('model.password'),
    passwords_match: Ember.computed('model.password', 'password_check', function () {
      return this.get('model.password') === this.get('password_check');
    }),
    passwords_ok: Ember.computed.and('password_provided', 'passwords_match'),
    passwords_not_ok: Ember.computed.not('passwords_ok'),
    actions: {
      toggleEdit: function () {
        this.set('editing', !this.get('editing'));
      },
      cancelUser: function () {
        var model = this.get('model');
        this.set('editing', false);
        this.set('password_check', null);
        if (model.get('isNew')) {
          model.deleteRecord();
          this.transitionToRoute('users');
        } else {
          model.rollbackAttributes();
        }
      },
      save: function () {
        if (this.get('passwords_ok')) {
          this.set('password_check', null);
          this.set('editing', false);
          return true;
        } else {
          if (this.get('password_provided')) {
            alert('Provided passwords do not match');
          } else {
            alert('No password provided');
          }
          this.set('model.password', null);
          this.set('password_check', null);
          return false;
        }
      }
    }
  });
});