define('saves-client/models/hall-entity', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    hall: _emberData.default.belongsTo('hall', { async: true }),
    entity: _emberData.default.belongsTo('entity', { async: true }),
    multiplier: _emberData.default.attr('number', { defaultValue: 1.0 })
  });
});