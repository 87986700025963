define('saves-client/pods/components/hall-draggable/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['draggable', 'hall'],
    attributeBindings: ['draggable'],
    draggable: "true",
    dragStart: function (event) {
      event.dataTransfer.setData('text/data', this.get('hall.id'));
    }
  });
});