define('saves-client/pods/normal/organisation/organisation-users/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    account: Ember.inject.service(),
    organisation: Ember.inject.controller('normal.organisation'),
    canCreate: function () {
      var newFullname = this.get('newFullname');
      var newPassword = this.get('newPassword');
      var confirmPassword = this.get('confirmPassword');
      if (Ember.isEmpty(newFullname) || Ember.isEmpty(newPassword)) {
        return false;
      } else {
        return confirmPassword === newPassword;
      }
    }.property('newUsername', 'newPassword', 'confirmPassword'),

    actions: {
      createOrgUser: function () {
        var newFullname = this.get('newFullname');
        var newPassword = this.get('newPassword');
        var organisation = this.get('organisation.model');
        var new_user = this.store.createRecord('user', {
          username: newFullname,
          fullname: newFullname,
          password: newPassword,
          administrator: false
        });
        var self = this;
        new_user.save().then(function (user) {

          var new_org_user = self.store.createRecord('organisation_user', {
            organisation: organisation,
            user: user
          });

          new_org_user.save().then(function (org_user) {
            organisation.get("organisation_users").pushObject(org_user);
            self.set('newFullname', null);
            self.set('newPassword', null);
            self.set('confirmPassword', null);
          });
        });
      }
    }
  });
});