define('saves-client/pods/application/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    routeAfterAuthentication: 'normal.index',
    account: Ember.inject.service(),
    beforeModel() {
      return this.get('account.currentUser');
    },
    actions: {
      didTransition: function () {
        let ga = window.ga || false;
        if (ga) {
          let url = this.get('router.url');
          return ga('send', 'pageview', {
            'page': url,
            'title': url
          });
        }
      }
    }
  });
});