define('saves-client/models/competitor', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    competition: _emberData.default.belongsTo('competition', { async: true }),
    hall: _emberData.default.belongsTo('hall', { async: true }),
    baseline_start_date: _emberData.default.attr('isodate'),
    baseline_end_date: _emberData.default.attr('isodate'),
    baseline_model: _emberData.default.attr('string'),
    hidden: _emberData.default.attr('boolean'),
    baseline_validated: _emberData.default.attr('boolean'),
    calculated: _emberData.default.attr('boolean'),
    has_data: _emberData.default.attr('boolean'),
    savings: _emberData.default.attr('number'),
    savings_date: _emberData.default.attr('isodate'),
    ic_has_data: _emberData.default.attr('boolean'),
    ic_savings: _emberData.default.attr('number'),
    ic_savings_date: _emberData.default.attr('isodate'),
    message: _emberData.default.attr('string'),
    performances: _emberData.default.hasMany('performance', { async: true }),

    absolute_savings: function () {
      return Math.abs(this.get('savings'));
    }.property('savings'),

    latest_performance: function () {
      var performances = this.get('performances');
      return performances.filterBy('actual').get('lastObject');
    }.property('performances.@each.savings'),
    target: function () {
      var performances = this.get('performances');
      return performances.reduce(function (previousValue, performance) {
        if (performance.get('include_in_cumulative')) {
          return previousValue + performance.get("target");
        } else {
          return previousValue;
        }
      }, 0);
    }.property('performances.@each.target'),

    adjustment: function () {
      var performances = this.get('performances');
      return performances.reduce(function (previousValue, performance) {
        if (performance.get('include_in_cumulative')) {
          return previousValue + parseFloat(performance.get("adjustment")); // the input field provides text - not ideal
        } else {
          return previousValue;
        }
      }, 0);
    }.property('performances.@each.adjustment'),

    adjusted_target: function () {
      var performances = this.get('performances');
      return performances.reduce(function (previousValue, performance) {
        if (performance.get('include_in_cumulative')) {
          return previousValue + performance.get("adjusted_target");
        } else {
          return previousValue;
        }
      }, 0);
    }.property('performances.@each.adjusted_target'),

    actual: function () {
      var performances = this.get('performances');
      return performances.reduce(function (previousValue, performance) {
        if (performance.get('include_in_cumulative')) {
          return previousValue + performance.get("actual");
        } else {
          return previousValue;
        }
      }, 0);
    }.property('performances.@each.actual'),

    cumulative_savings: function () {
      var performances = this.get('performances');
      var data = performances.reduce(function (previousValue, performance) {
        if (performance.get('include_in_cumulative')) {
          var actual = performance.get("actual");
          var target = performance.get("adjusted_target");
          return {
            target: previousValue.target + target,
            actual: previousValue.actual + actual
          };
        } else {
          return previousValue;
        }
      }, { target: 0, actual: 0 });
      if (data.target && data.actual) {
        return (data.target - data.actual) / data.target * 100;
      } else {
        return null;
      }
    }.property('performances.@each.adjusted_target', 'performances.@each.actual'),
    absolute_cumulative_savings: function () {
      return Math.abs(this.get('cumulative_savings'));
    }.property('cumulative_savings'),
    positive: function () {
      return this.get('savings') >= 0;
    }.property('savings')
  });
});