define('saves-client/pods/normal/organisation/competitions/competition/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel(transition) {
      this.replaceWith('normal.organisation.competition', transition.params['normal.organisation.competitions.competition'].competition_id); // Implicitly aborts the on-going transition.
    }
  });
});