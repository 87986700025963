define('saves-client/pods/components/tip-viewer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // tagName: 'section',
    classNames: ['tip-viewer', 'anti-snazzy'],
    organisation: null,
    controls: true,
    tips: Ember.computed.alias('organisation.tips'),
    count: Ember.computed.alias('tips.length'),
    index: 0,
    tip_number: Ember.computed('index', function () {
      return this.get('index') + 1;
    }),
    rotate: true,
    interval: 5000,
    currentTimeMetronome: function () {
      let interval = this.get('interval');
      Ember.run.later(this, function () {
        this.notifyPropertyChange('currentTimePulse');
        if (this.get('rotate')) {
          // Only do this when its not been destroyed
          if (!(this.get('isDestroyed') || this.get('isDestroying'))) {
            this.set('index', (this.get('index') + 1) % this.get('count'));
          }
        }
        this.currentTimeMetronome();
      }, interval);
    }.on('init'),
    currentTip: function () {
      return this.get('tips').objectAt(this.get('index'));
    }.property('tips', 'index'),
    actions: {
      nextTip() {
        let tips = this.get('tips');
        let index = this.get('index');
        this.set('index', (index + 1) % tips.get('length'));
      },
      prevTip() {
        let tips = this.get('tips');
        let index = this.get('index');
        index = (index + tips.get('length') - 1) % tips.get('length');
        console.log(index);
        this.set('index', index);
      },
      toggleRotate() {
        this.toggleProperty('rotate');
      }
    }
  });
});