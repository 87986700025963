define('saves-client/pods/normal/international/new/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      save: function () {
        var model = this.get('model');
        var _this = this;
        model.save().then(function (comp) {
          _this.transitionToRoute('international.show', comp.id);
        });
      }
    }
  });
});