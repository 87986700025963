define('saves-client/pods/components/performance-chart/component', ['exports', 'd3-selection', 'd3-scale', 'd3-array', 'd3-axis', 'd3-shape'], function (exports, _d3Selection, _d3Scale, _d3Array, _d3Axis, _d3Shape) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'svg',
    classNames: ['performance-chart'],
    width: 500,
    height: 300,
    performances: null,
    viewBox: Ember.computed('width', 'height', function () {
      return `0 0 ${this.get('width')} ${this.get('height')}`;
    }),
    attributeBindings: ['viewBox', 'width', 'height'],
    chart_data: Ember.computed('performances', function () {
      let performances = this.get('performances');
      let result = performances.map(function (p) {
        return {
          target: p.get('target'),
          adjusted_target: p.get('adjusted_target'),
          actual: p.get('actual'),
          date: p.get('start_date')
        };
      });
      return result;
    }),
    didReceiveAttrs() {
      // Schedule a call to our `draw` method on Ember's "render" queue, which will
      // happen after the component has been placed in the DOM, and subsequently
      // each time data is changed.
      (0, _d3Selection.select)(this.element).selectAll("*").remove();
      Ember.run.scheduleOnce('render', this, this.draw);
    },
    draw() {
      let data = this.get('chart_data');
      let svg = (0, _d3Selection.select)(this.element);
      let svgWidth = +svg.attr("width"),
          svgHeight = +svg.attr("height"),
          margin = { top: 10, right: 10, bottom: 100, left: 80 },
          chartWidth = svgWidth - margin.left - margin.right,
          chartHeight = svgHeight - margin.top - margin.bottom;

      let x = (0, _d3Scale.scaleTime)().range([0, chartWidth]).domain((0, _d3Array.extent)(data, function (d) {
        return d.date;
      }));

      let y = (0, _d3Scale.scaleLinear)().range([chartHeight, 0]).domain([0, (0, _d3Array.max)(data, function (d) {
        return (0, _d3Array.max)([d.actual, d.adjusted_target]);
      })]).nice();

      let xAxis = (0, _d3Axis.axisBottom)().scale(x).tickSizeOuter(0).tickPadding(10),
          yAxis = (0, _d3Axis.axisLeft)().scale(y).tickSizeOuter(0).tickPadding(1).ticks(6);

      let ax = svg.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      this.drawPaths(ax, data, x, y);
      this.addAxesAndLegend(ax, xAxis, yAxis, margin, chartWidth, chartHeight);

      let legend = ax.append('g').attr('class', 'legend').attr('transform', `translate(0, ${chartHeight + 55})`);

      legend.append('rect').attr('class', 'area actual').attr('x', '10').attr('y', '5').attr('width', '10').attr('height', '10');

      legend.append('text').text('actual consumption').attr('alignment-baseline', "middle").attr('x', '25').attr('y', '10');

      legend.append('line').attr('class', 'line target').attr('x1', '10').attr('y1', '30').attr('x2', '20').attr('y2', '30');

      legend.append('text').text('baseline consumption').attr('alignment-baseline', "middle").attr('x', '25').attr('y', '30');

      legend.append('rect').attr('class', 'area good hover').attr('x', '200').attr('y', '5').attr('width', '10').attr('height', '10');

      legend.append('text').text('good performance').attr('alignment-baseline', "middle").attr('x', '215').attr('y', '10');

      legend.append('rect').attr('class', 'area bad hover').attr('x', '200').attr('y', '25').attr('width', '10').attr('height', '10');

      legend.append('text').text('bad performance').attr('x', '215').attr('y', '30');
    },

    addAxesAndLegend(svg, xAxis, yAxis, margin, chartWidth, chartHeight) {

      svg.append('g').attr('class', 'x axis').attr('transform', 'translate(0,' + chartHeight + ')').call(xAxis).selectAll("text").attr("y", -4).attr("x", 9).attr("dy", ".35em").attr("transform", "rotate(90)").style("text-anchor", "start");

      svg.append('g').attr('class', 'y axis').call(yAxis).append('text').attr('transform', 'rotate(-90)').attr('dy', '-6.5em').attr('dx', '-3.5em').style('text-anchor', 'end').text('Consumption (kWh)');
    },
    drawPaths(svg, data, x, y) {
      let actual = (0, _d3Shape.area)().x(function (d) {
        return x(d.date);
      }).y0(function (d) {
        return y(0);
      }).y1(function (d) {
        return y(d.actual);
      });

      let target = (0, _d3Shape.line)().x(function (d) {
        return x(d.date);
      }).y(function (d) {
        return y(d.adjusted_target);
      });

      let savings = (0, _d3Shape.area)().x(function (d) {
        return x(d.date);
      }).y0(function (d) {
        return y(d.actual);
      }).y1(function (d) {
        return y(d.adjusted_target);
      });

      var areaAboveTargetLine = (0, _d3Shape.area)().x(target.x()).y0(target.y()).y1(0);

      var areaBelowTargetLine = (0, _d3Shape.area)().x(target.x()).y0(target.y()).y1(function (d) {
        return y(0);
      });

      var defs = svg.append('defs');

      defs.append('pattern').attr('id', "pattern").attr('patternUnits', "userSpaceOnUse").attr('width', "10").attr('height', "10").append("image").attr('xlink:href', "data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnPgogIDxyZWN0IHdpZHRoPSc4JyBoZWlnaHQ9JzgnIGZpbGw9JyNmZmYnLz4KICA8cGF0aCBkPSdNMCAwTDggOFpNOCAwTDAgOFonIHN0cm9rZS13aWR0aD0nMC41JyBzdHJva2U9JyNhYWEnLz4KPC9zdmc+Cg==").attr('x', "0").attr('y', "0").attr('width', "10").attr('height', "10");

      defs.append('clipPath').attr('id', 'clip-increases').append('path').datum(data).attr('d', areaAboveTargetLine);

      defs.append('clipPath').attr('id', 'clip-decreases').append('path').datum(data).attr('d', areaBelowTargetLine);

      svg.datum(data);

      svg.append('path')
      // .style("fill", "url(#pattern)")
      .attr('class', 'area actual').attr('d', actual);

      svg.append('path').attr('class', 'line target').attr('d', target);

      svg.append('path').attr('class', 'area bad').attr('d', savings).attr('clip-path', 'url(#clip-increases)');

      svg.append('path').attr('class', 'area good').attr('d', savings).attr('clip-path', 'url(#clip-decreases)');
    }
  });
});