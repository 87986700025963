define('saves-client/pods/components/league-table/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'article',
    classNames: ['league-table'],
    competition: null,
    interval: null, //to be specified in seconds
    heartbeat: function () {
      let interval = this.get('interval');
      if (interval) {
        Ember.run.later(this, function () {
          this.send('reloadCompetition', this.get('competition'));
          this.heartbeat();
        }, interval * 1000); // multiply by 1000 to give microseconds
      }
    }.on('init'),
    sortProperties: ['savings:desc'],
    sortedCompetitors: Ember.computed.sort('competition.competitors', 'sortProperties'),
    link: 'normal.organisation.competitor',
    subtitle: Ember.computed('competition.start_date', 'competition.end_date', function () {
      let start_date = _moment.default.utc(this.get('competition.start_date'));
      let end_date = _moment.default.utc(this.get('competition.end_date'));
      if (start_date.isAfter()) {
        return `starts ${start_date.format('Do MMM YYYY')}`;
      } else if (end_date.isBefore()) {
        return `ended ${end_date.format('Do MMM YYYY')}`;
      } else if (start_date.isBefore() && end_date.isAfter()) {
        return `ends ${end_date.format('Do MMM YYYY')}`;
      }
    }),
    has_message: Ember.computed.bool('competition.msg_title'),
    has_message_body: Ember.computed.bool('competition.msg_body'),
    showing_message: false,
    actions: {
      show_message() {
        this.set('showing_message', this.get('has_message_body'));
      },
      clear_message() {
        this.set('showing_message', false);
      },
      reloadCompetition(competition) {
        competition.reload();
        competition.get('competitors').reload();
      }
    }
  });
});