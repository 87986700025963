define('saves-client/models/entity', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    hall_entities: _emberData.default.hasMany('hallEntity'),
    devices: _emberData.default.hasMany('device', { async: true }),

    earliest_complete_data: function () {
      var result;
      this.get('devices').forEach(function (device) {
        var device_start = moment(device.get('start_date'));
        if (!moment.isMoment(result)) {
          result = device_start;
        } else {
          if (device_start.isAfter(result)) {
            result = device_start;
          }
        }
      });
      return result;
    }.property('devices.@each.start_date'),

    latest_complete_data: function () {
      var result;
      this.get('devices').forEach(function (device) {
        var device_end = moment(device.get('end_date'));
        if (!moment.isMoment(result)) {
          result = device_end;
        } else {
          if (device_end.isBefore(result)) {
            result = device_end;
          }
        }
      });
      return result;
    }.property('devices.@each.end_date')
  });
});