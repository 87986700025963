define('saves-client/pods/normal/new-org/controller', ['exports', 'saves-client/utils/slug'], function (exports, _slug) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    replace_url: false,
    proposed_orgname: function () {
      var replace_url = this.get('replace_url');
      var alternative_id = this.get('alternative_id');
      if (replace_url && alternative_id) {
        return (0, _slug.default)(alternative_id);
      } else {
        return (0, _slug.default)(this.get('model.fullname'));
      }
    }.property('model.fullname', 'alternative_id', 'replace_url'),
    proposed_url: function () {
      return location.protocol + '//' + location.hostname + "/" + this.get('proposed_orgname');
    }.property('proposed_orgname'),
    actions: {
      save: function () {
        var _this = this;
        var model = this.get('model');
        model.set('orgname', this.get('proposed_orgname'));
        model.save().then(function (new_org) {
          _this.transitionToRoute('normal.organisation', new_org.id);
          _this.set('alternative_id', null);
          _this.set('replace_url', false);
        }, function () {
          alert("Sorry. The server didn't like it.\n\nPerhaps that name is already in use?");
        });
      }
    }
  });
});