define('saves-client/pods/components/target-editor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['target-editor'],
    competitor: null,
    from_date: null,
    to_date: null,
    use_percentage: false,
    adjustment: null,
    valid_adjustment: Ember.computed('adjustment', function () {
      var adj = this.get('adjustment');
      return adj !== 0;
    }),
    range_complete: Ember.computed.and('to_date', 'from_date'),
    ready_to_apply: Ember.computed.and('range_complete', 'adjustment'),
    actions: {
      apply: function () {
        var props = this.getProperties('from_date', 'to_date', 'use_percentage', 'adjustment');
        this.sendAction('apply', props);
      }
    }
  });
});