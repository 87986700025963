define('saves-client/pods/components/org-summary-row/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "tr",
    org: null,
    upload_warning: 8,
    logo_class: Ember.computed('org.has_logo', function () {
      if (!this.get('org.has_logo')) {
        return 'highlighted';
      }
    }),
    hall_class: Ember.computed('org.hall_count', function () {
      if (!this.get('org.hall_count')) {
        return 'highlighted';
      }
    }),
    dataset_class: Ember.computed('org.entity_count', function () {
      if (!this.get('org.entity_count')) {
        return 'highlighted';
      }
    }),
    file_class: Ember.computed('org.file_count', function () {
      if (!this.get('org.file_count')) {
        return 'highlighted';
      }
    }),
    competition_class: Ember.computed('org.competition_count', function () {
      if (!this.get('org.competition_count')) {
        return 'highlighted';
      }
    }),
    published_class: Ember.computed('org.published', function () {
      if (!this.get('org.published')) {
        return 'highlighted';
      }
    }),
    latest_upload_class: Ember.computed('org.latest_upload', 'upload_warning', function () {
      let date = this.get('org.latest_upload');
      if (!date || date < (0, _moment.default)().subtract(this.get('upload_warning'), 'days')) {
        return 'highlighted';
      }
    }),
    latest_upload: Ember.computed('org.latest_upload', function () {
      return (0, _moment.default)(this.get('org.latest_upload')).fromNow(); //format('DD-MM-YYYY');
    }),
    actions: {
      delete() {
        let summary = this.get('org');
        if (confirm('Are you sure?\n\nThis will delete ' + summary.get('fullname') + ' and all associated data.')) {
          summary.get('organisation').then(org => {
            org.destroyRecord().then(() => {
              summary.deleteRecord();
            }, () => {
              org.rollbackAttributes();
            });
          });
        }
      },
      edit_org() {
        this.sendAction('edit_org', this.get('org'));
      }
    }
  });
});