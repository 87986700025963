define('saves-client/pods/normal/users/index/controller', ['exports', 'saves-client/utils/slug'], function (exports, _slug) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    account: Ember.inject.service(),
    session: Ember.inject.service(),
    actions: {
      createAdminUser: function () {
        var newFullname = this.get('newFullname');
        var new_user = this.store.createRecord('user', {
          id: (0, _slug.default)(newFullname),
          username: (0, _slug.default)(newFullname),
          fullname: newFullname,
          administrator: true
        });
        this.set('newFullname', null);
        this.transitionToRoute('user', new_user.id);
      }
    }
  });
});