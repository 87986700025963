define('saves-client/pods/normal/organisation/organisation-users/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    account: Ember.inject.service(),
    authenticationRoute: 'normal.login',
    beforeModel: function () {
      if (!this.get('account.canEditOrg')) {
        this.transitionTo('normal.organisation', this.modelFor('organisation'));
      }
    },
    model: function () {
      return this.modelFor('normal.organisation').get('organisation_users');
    }
    // setupController: function (controller, model) {
    //   this._super(controller, model);
    //   controller.set('organisation', this.modelFor('organisation'));
    // }
  });
});