define('saves-client/models/international-competition', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    start_date: _emberData.default.attr('isodate'),
    end_date: _emberData.default.attr('isodate'),
    locked: _emberData.default.attr('boolean'),
    live: _emberData.default.attr('boolean'),
    open: _emberData.default.attr('boolean'),
    international_registrants: _emberData.default.hasMany('international_registrant', { async: true }),
    competitors: _emberData.default.hasMany('competitor', { async: true }),
    top_ten: _emberData.default.hasMany('competitor', { async: true }),
    inFuture: function () {
      var start_date = _moment.default.utc(this.get('start_date'));
      return start_date.isAfter();
    }.property('start_date'),
    inPast: function () {
      var end_date = _moment.default.utc(this.get('end_date'));
      return end_date.isBefore();
    }.property('end_date'),
    isCurrent: function () {
      var start_date = _moment.default.utc(this.get('start_date'));
      var end_date = _moment.default.utc(this.get('end_date'));
      return start_date.isBefore() && end_date.isAfter();
    }.property('start_date', 'end_date')
  });
});