define('saves-client/pods/normal/organisation/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    account: Ember.inject.service(),
    model: function (params) {
      let self = this;
      return this.store.findRecord('organisation', params.orgname).then(null, function (error) {
        if (error.status === 401) {
          self.transitionTo('normal.login');
        } else {
          self.transitionTo('normal.index');
        }
      });
    },
    afterModel: function (model) {
      // set the organisation and get the list of users (promise will be resolved)
      this.get('account').set('organisation', model);
      if (this.get('session.isAuthenticated')) {
        return this.get('account.organisation_users');
      }
    },
    actions: {
      save: function () {
        this.modelFor('normal.organisation').save();
      },
      delete: function () {
        var self = this;
        var model = this.modelFor('normal.organisation');
        model.one('didDelete', this, function () {
          self.transitionTo('index');
        });
        if (confirm("Are you sure you want to delete '" + model.get('fullname') + "' and all attached data?")) {
          model.deleteRecord();
          model.save();
        }
      }
    }
  });
});