define('saves-client/models/measurement', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    timestamp: _emberData.default.attr('isodate'),
    value: _emberData.default.attr('number'),
    month: function () {
      var ts = this.get('timestamp');
      return ts.clone().utc().startOf('month');
    }.property('timestamp'),
    week: function () {
      var ts = this.get('timestamp');
      return ts.clone().utc().startOf('week');
    }.property('timestamp'),
    day: function () {
      var ts = this.get('timestamp');
      return ts.clone().utc().startOf('day');
    }.property('timestamp')
  });
});