define('saves-client/pods/components/international-dropzone/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    ic: null,
    highlight: false,
    classNames: ['dropzone'],
    classNameBindings: ['highlight'],
    dragOver: function (event) {
      this.set('highlight', true);
      event.preventDefault();
    },
    dragLeave: function (event) {
      this.set('highlight', false);
      event.preventDefault();
    },
    drop: function (event) {
      this.set('highlight', false);
      var ic = this.get('ic');
      if (this.get('ic.open')) {
        var competition_id = event.dataTransfer.getData('text/data');
        this.sendAction('registerCompetition', ic, competition_id);
      } else {
        alert(ic.get('name') + " is not open for registration.");
      }
    }
  });
});