define('saves-client/pods/components/international-competitions-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['itemlist'],
    isAllowed: false, // true if is allowed to edit and stuff
    draggable: false,
    competitions: null,
    content: Ember.computed.filterBy('competitions', 'isNew', false),
    actions: {
      setLiveIC: function (ic) {
        this.sendAction('setLiveIC', ic);
      },
      setOpenIC: function (ic) {
        this.sendAction('setOpenIC', ic);
      }
    }
  });
});