define('saves-client/pods/components/international-registration-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['itemlist'],
    ic: null,
    organisation: null
  });
});