define('saves-client/pods/normal/users/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    sortedUsers: Ember.computed.sort('model', 'sortProperties'),
    sortProperties: ['fullname']
  });
});