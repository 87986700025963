define('saves-client/pods/components/organisation-logo/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'img',
    classNames: ['orglogo'],
    classNameBindings: ["organisation.published::unpublished"],
    attributeBindings: ['title', 'alt', 'logoUrl:src'],
    refresh: +new Date(),
    organisation: null,
    title: Ember.computed.readOnly('organisation.fullname'),
    alt: Ember.computed.readOnly('organisation.fullname'),
    logoUrl: function () {
      if (this.get('organisation.has_logo')) {
        return this.get('organisation.logoEndPoint') + "?" + this.get('refresh');
      } else {
        return "/assets/images/default-org.png";
      }
    }.property('organisation.logoEndPoint', 'organisation.has_logo', 'refresh')
  });
});