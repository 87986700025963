define('saves-client/pods/components/international-competition-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['runState'],
    ic: null,
    isAllowed: false,
    draggable: false,
    _international_registrants: function () {
      return this.get('ic') && this.get('ic').get('international_registrants');
    }.property('ic.international_registrants'),
    can_delete: Ember.computed.empty('_international_registrants'),
    runState: function () {
      if (this.get('competition.isCurrent')) {
        return "live";
      }
      if (this.get('competition.inPast')) {
        return "past";
      }
      if (this.get('competition.inFuture')) {
        return "future";
      }
    }.property('competition.isCurrent', 'competition.inPast', 'competition.inFuture'),
    actions: {
      setLiveIC: function () {
        var ic = this.get('ic');
        if (confirm("Setting " + ic.get('name') + " to be LIVE will place it on the home page.\n\nAre you sure you want to make it LIVE?")) {
          this.sendAction('setLiveIC', ic);
        }
      },
      clearLive: function () {
        var ic = this.get('ic');
        if (confirm("Setting " + ic.get('name') + " to no longer be LIVE will leave the dashboard with no LIVE international competition.\n\nAre you sure you want no LIVE competition?")) {
          ic.set('live', false);
          ic.save();
        }
      },
      open: function () {
        var ic = this.get('ic');
        if (true || confirm("Opening " + ic.get('name') + " will allow universities to register competitions.\n\nAre you sure you want to make it OPEN?")) {
          this.sendAction('setOpenIC', ic);
        }
      },
      close: function () {
        var ic = this.get('ic');
        if (true || confirm("Closing " + ic.get('name') + " will prevent universities from registering.\n\nAre you sure you want to close it?")) {
          ic.set('open', false);
          ic.save();
        }
      },
      toggleLocked: function () {
        var ic = this.get('ic');
        if (ic.get('open')) {
          alert("Open international competitions cannot be locked.");
        } else {
          if (!ic.get('locked') || confirm("Unlocking a locked competition is dangerous and can lead to the order of the competition irreversibly changing.\n\nAre you sure you want to unlock?")) {
            ic.toggleProperty('locked');
            ic.save();
          }
        }
      },
      delete: function () {
        var ic = this.get('ic');
        if (confirm("Are you sure you want to remove international competition '" + ic.get('name') + "'?")) {
          ic.destroyRecord();
        }
      }
    }
  });
});