define('saves-client/pods/normal/organisation/competitions/edit-competition/controller', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    account: Ember.inject.service(),
    organisation: Ember.inject.controller('normal.organisation'),
    editing: true,
    note: function () {
      var name = this.get('model.name');
      if (this.get('model.isCurrent')) {
        return name + " is live! Ends " + (0, _moment.default)().to(this.get('model.end_date'));
      }
      if (this.get('model.inPast')) {
        return name + " finished " + (0, _moment.default)().to(this.get('model.end_date'));
      }
      if (this.get('model.inFuture')) {
        return name + " has not started yet! Starts " + (0, _moment.default)().to(this.get('model.start_date'));
      }
    }.property('model.isCurrent', 'model.inPast', 'model.inFuture', 'model.end_date', 'model.start_date'),
    icon: Ember.computed('model.isCurrent', 'model.inPast', 'model.inFuture', function () {
      if (this.get('model.isCurrent')) {
        return "dashboard";
      }
      if (this.get('model.inPast')) {
        return "trophy";
      }
      if (this.get('model.inFuture')) {
        return "gears";
      }
    }),
    runState: Ember.computed('model.isCurrent', 'model.inPast', 'model.inFuture', function () {
      if (this.get('model.isCurrent')) {
        return "live";
      }
      if (this.get('model.inPast')) {
        return "past";
      }
      if (this.get('model.inFuture')) {
        return "future";
      }
    }),
    actions: {
      ToggleEditable: function () {
        this.set('editing', !this.get('editing'));
      },
      setMainCompetition: function (competition) {
        this.get('organisation').send('setMainCompetition', competition);
      },
      attachHall: function (hall_id) {
        var competition = this.get('model');
        var baseline_start_date = competition.get('default_baseline_start');
        var baseline_end_date = competition.get('default_baseline_end');
        var baseline_model = competition.get('default_baseline_model');

        var _store = this.store;
        this.store.findRecord('hall', hall_id).then(function (myhall) {
          var new_competitor = _store.createRecord('competitor', {
            competition: competition,
            hall: myhall,
            baseline_start_date: baseline_start_date,
            baseline_end_date: baseline_end_date,
            baseline_model: baseline_model
          });
          new_competitor.save().then(function (saved_competitor) {
            competition.get('competitors').pushObject(saved_competitor);
          }, function () {
            alert("Could not add hall '" + myhall.get('name') + "' to competition '" + competition.get('name') + "'");
            new_competitor.destroyRecord();
          });
        }, function () {
          console.log("Something went wrong");
        });
      },
      delete: function () {
        var competition = this.get('model');
        if (confirm("Are you sure you want to remove competition '" + competition.get('name') + "'?")) {
          var organisation = this.get('organisation.model');
          var _this = this;
          competition.destroyRecord().then(function () {
            _this.transitionToRoute('organisation.competitions', organisation);
          });
        }
      },
      save: function () {
        this.get('model').save().then(function (c) {
          c.get('competitors').reload();
        });
      }
    }
  });
});