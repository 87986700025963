define('saves-client/pods/components/date-picker/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['value', 'type'],
    classNames: ['form-control'],
    tagName: 'input',
    type: 'date',
    date: null,
    format: 'YYYY-MM-DD',

    value: Ember.computed('date', 'format', function () {
      let date = this.get('date'),
          format = this.get('format') || 'YYYY-MM-DD';
      return (0, _moment.default)(date).format(format);
    }),

    change: function () {
      var value = this.$().val();
      this.set('date', (0, _moment.default)(value));
    }
  });
});