define('saves-client/pods/application/adapter', ['exports', 'ember-data', 'saves-client/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _environment, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    authorizer: 'authorizer:saves',
    host: _environment.default.APP.API_HOST,
    namespace: _environment.default.APP.API_NAMESPACE
  });
});