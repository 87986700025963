define('saves-client/pods/components/file-upload/component', ['exports', 'ember-uploader'], function (exports, _emberUploader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberUploader.default.FileField.extend({
    url: '',
    progress: 0,
    session: Ember.inject.service(),
    filesDidChange: function (files) {
      const MyUploader = _emberUploader.default.Uploader.extend({
        ajaxSettings: {
          headers: {
            'Authorization': this.get('session.data.authenticated.access_token')
          }
        }
      });

      const uploader = MyUploader.create({
        url: this.get('url')
      });
      var _this = this;

      uploader.on('progress', function (e) {
        _this.set('progress', e.percent);
      });

      uploader.on('didUpload', function () {
        _this.sendAction('complete');
      });

      if (!Ember.isEmpty(files)) {
        uploader.upload(files[0], { MAX_FILE_SIZE: 100000 }).then(function (response) {
          console.log("UPLOAD COMPLETE! - response follows");
          console.log(response);
        }, function (response) {
          alert(response.responseJSON.errors[0].description);
        });
      }
    }
  });
});