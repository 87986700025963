define('saves-client/models/tip', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    orgname: _emberData.default.belongsTo('organisation'),
    title: _emberData.default.attr('string'),
    body: _emberData.default.attr('string'),
    url: _emberData.default.attr('string')
  });
});