define('saves-client/authenticators/saves', ['exports', 'ember-simple-auth/authenticators/base', 'saves-client/config/environment'], function (exports, _base, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var api_path = _environment.default.APP.API_HOST;
  if (_environment.default.APP.API_NAMESPACE) {
    api_path = api_path + '/' + _environment.default.APP.API_NAMESPACE;
  }

  exports.default = _base.default.extend({
    restore: function (data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(data.user_id) && !Ember.isEmpty(data.access_token)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },
    authenticate: function (credentials) {
      var postData = { user: { username: credentials.identification, password: credentials.password } };
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          url: api_path + '/sessions',
          type: 'POST',
          data: JSON.stringify(postData),
          contentType: 'application/json',
          dataType: 'json'
        }).then(function (response) {
          Ember.run(function () {
            console.log("User [" + response.session.username + "] authenticated!");
            resolve({ access_token: response.session.token, user_id: response.session.username });
          });
        }, function (xhr) {
          var response = JSON.parse(xhr.responseText);
          alert("Invalid login details");
          Ember.run(function () {
            reject(response.error);
          });
        });
      });
    },
    invalidate: function (data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          url: api_path + '/sessions/' + data.access_token, //App.ApplicationAdapter.prototype.buildURL('session'),
          type: 'DELETE',
          contentType: 'application/json',
          dataType: 'json',
          headers: { "Authorization": data.access_token }
        }).then(function (response) {
          console.log("invalidating token?");
          console.log(response);
          Ember.run(function () {
            resolve();
          });
        }, function (xhr) {
          var response = JSON.parse(xhr.responseText);
          Ember.run(function () {
            console.log("Server failed to invalidate token!");
            reject(response.error);
          });
        });
      });
    }
  });
});