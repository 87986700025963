define('saves-client/pods/components/action-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "span",
    classNames: ['action-button'],
    classNameBindings: ['right:floatright', 'warn', 'selected', 'unselected', 'action:clickable'],
    right: true,
    selected: false,
    unselectable: false,
    unselected: function () {
      return this.get('unselectable') && !this.get('selected');
    }.property('unselectable', 'selected'),
    icon: "dot-circle-o",
    title: null,
    click: function () {
      this.sendAction();
    }
  });
});