define('saves-client/pods/components/organisation-user-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    account: Ember.inject.service(),
    tagName: 'li',
    editing: false,
    user: Ember.computed.alias('organisation-user.user'),
    isMe: function () {
      var user = this.get('user.id');
      var session_user = this.get('currentUser.id');
      return user === session_user;
    }.property('currentUser', 'user'),
    isEditableByMe: function () {
      var session_is_admin = this.get('account.currentUser.administrator');
      return session_is_admin || this.get('isMe');
    }.property('isMe'),
    passwordsMatch: function () {
      var pwd = this.get('user.password');
      var check = this.get('password_check');
      return pwd && pwd === check;
    }.property('user.password', 'password_check'),
    actions: {
      toggleEditable: function () {
        this.toggleProperty('editing');
        if (!this.get('editing')) {
          this.set('change_password', false);
        }
      },
      save: function () {
        var self = this;
        this.get('user').then(function (user) {
          if (user.get('hasDirtyAttributes')) {
            user.save().then(function () {
              self.set('editing', false);
            });
          }
        });
      },
      deleteMe: function () {
        let org_user = this.get('organisation-user');
        if (confirm("delete " + this.get('user.fullname') + "?")) {
          org_user.deleteRecord();
          org_user.save();
        }
      },
      toggleChangePassword: function () {
        this.toggleProperty('change_password');
      }
    }

  });
});