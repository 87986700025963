define('saves-client/pods/components/file-item/component', ['exports', 'saves-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var api_path = _environment.default.APP.API_HOST;
  if (_environment.default.APP.API_NAMESPACE) {
    api_path = api_path + '/' + _environment.default.APP.API_NAMESPACE;
  }

  exports.default = Ember.Component.extend({
    tagName: 'li',
    file: null,
    file_url: Ember.computed('file.url', function () {
      return api_path + this.get('file.url');
    }),
    actions: {
      delete: function () {
        var file = this.get('file');
        if (confirm("Are you sure you want to remove '" + file.get('filename') + "'?")) {
          file.deleteRecord();
          file.save();
        }
      },
      reset: function () {
        var file = this.get('file');
        file.set('reset', true);
        file.save();
      }
    }
  });
});