define('saves-client/pods/components/error-message/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    summary: null,
    detail: null,
    icon: "exclamation-triangle",
    attributeBindings: ['title'],
    title: Ember.computed.alias('detail')
  });
});