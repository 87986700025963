define('saves-client/models/organisation-summary', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    organisation: _emberData.default.belongsTo('organisation', { async: true }),
    fullname: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    hall_count: _emberData.default.attr('number'),
    entity_count: _emberData.default.attr('number'),
    competition_count: _emberData.default.attr('number'),
    has_logo: _emberData.default.attr('boolean'),
    user_count: _emberData.default.attr('number'),
    published: _emberData.default.attr('boolean'),
    file_count: _emberData.default.attr('number'),
    latest_upload: _emberData.default.attr('isodate')
  });
});