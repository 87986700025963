define('saves-client/pods/normal/organisation/international/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    organisation: Ember.inject.controller('normal.organisation'),
    actions: {
      registerCompetition: function (ic, competition_id) {
        var _this = this;
        this.store.findRecord('competition', competition_id).then(function (competition) {
          var registrant = _this.store.createRecord('international_registrant', {
            competition: competition,
            international_competition: ic,
            organisation: competition.get('organisation')
          });
          registrant.save().then(function () {}, function () {
            registrant.deleteRecord();
          });
        });
      }
    }
  });
});