define('saves-client/pods/components/measurement-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['measurement-item'],
    measurement: null,
    editing: false,
    actions: {
      toggleEdit: function () {
        this.toggleProperty('editing');
      },
      save: function () {
        this.get('measurement').save();
        this.set('editing', false);
      },
      delete: function () {
        if (confirm("Confirm delete measurement?")) {
          this.get('measurement').deleteRecord();
          this.get('measurement').save();
        }
        this.set('editing', false);
      },
      cancel: function () {
        this.get('measurement').rollbackAttributes();
        this.set('editing', false);
      }
    }
  });
});