define('saves-client/models/international-registrant', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    competition: _emberData.default.belongsTo('competition', { async: false }),
    international_competition: _emberData.default.belongsTo('international_competition', { async: true }),
    organisation: _emberData.default.belongsTo('organisation', { async: true })
  });
});