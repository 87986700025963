define('saves-client/pods/normal/international/show/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    international: Ember.inject.controller('normal.international'),
    actions: {
      toggleLocked: function () {
        var model = this.get('model');
        if (model.get('open')) {
          alert("Open international competitions cannot be locked.");
        } else {
          if (!model.get('locked') || confirm("Unlocking a locked competition is dangerous and can lead to the order of the competition irreversibly changing.\n\nAre you sure you want to unlock?")) {
            model.toggleProperty('locked');
            model.save();
          }
        }
      },
      toggleOpen: function () {
        var model = this.get('model');
        if (model.get('locked')) {
          alert("Unlock competition first");
        } else {
          model.toggleProperty('open');
          model.save();
        }
      },
      toggleLive: function (ic) {
        var international = this.get('international');
        international.send('toggleLive', ic);
      },
      save: function () {
        var ic = this.get('model');
        ic.save();
      },
      cancel: function () {
        var ic = this.get('model');
        ic.rollbackAttributes();
      }
    }
  });
});