define("saves-client/transforms/isodate", ["exports", "ember-data", "moment"], function (exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    // The deserialize function just transforms the string it receives from the
    // server JSON response into a date.
    deserialize: function (serialized) {
      if (serialized) {
        return _moment.default.utc(serialized); //.toDate();
      }
      return null;
    },
    //The serialize function takes the value of the attribute on the model and
    //transforms it into a nice ISO date to send to our server.
    serialize: function (deserialized) {
      if (deserialized) {
        return _moment.default.utc(deserialized).toISOString();
      }
    }
  });
});