define('saves-client/pods/normal/organisation/competitions/edit-competition/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authenticationRoute: 'normal.login',
    model: function (params) {
      return this.store.findRecord('competition', params.competition_id);
    },
    actions: {
      willTransition: function () {
        this.controller.set('editing', true);
      }
    }
  });
});