define('saves-client/pods/normal/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    session: Ember.inject.service(),
    account: Ember.inject.service(),
    actions: {
      invalidateSession() {
        this.get('session').invalidate();
      }
    }
  });
});