define("saves-client/helpers/rounded-float", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function ([float, decimals]) {
    float = parseFloat(float) || 0;
    return float.toFixed(decimals);
  });
});