define('saves-client/pods/components/measurement-month/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['measurement-month'],
    month: null,
    data: [],
    sortedData: Ember.computed.sort('data', function (a, b) {
      if (a.get('timestamp').isAfter(b.get('timestamp'))) {
        return 1;
      } else if (a.get('timestamp').isBefore(b.get('timestamp'))) {
        return -1;
      }
      return 0;
    }),
    open: false,
    actions: {
      toggleOpen: function () {
        this.toggleProperty('open');
      }
    }
  });
});