define('saves-client/pods/normal/login/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    identification: null,
    password: null,
    actions: {
      authenticate() {
        let credentials = this.getProperties('identification', 'password');
        this.get('session').authenticate('authenticator:saves', credentials).then(() => {
          this.set('identification', null);
          this.set('password', null);
        }).catch(reason => {
          this.set('errorMessage', reason.error || reason);
        });
      }
    }
  });
});