define('saves-client/router', ['exports', 'saves-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('normal', { path: '/' }, function () {
      this.route('int');
      this.route('login');
      this.route('new_org', { path: '/organisations/new' });
      this.route('orgs_status', { path: '/organisations/status' });
      this.route('users', function () {
        this.route('user', { path: '/:username' });
      });
      this.route('international', function () {
        this.route('new');
        this.route('show', { path: '/:ic_id' });
      });
      this.route('organisation', { path: '/:orgname' }, function () {
        this.route('competition', { path: 'competition/:competition_id' });
        this.route('competitions', function () {
          this.route('competition', { path: '/:competition_id' });
          this.route('edit-competition', { path: '/:competition_id/edit' });
        });
        this.route('international', { path: '/competitions/international' });
        this.route('competitor', { path: '/competitors/:competitor_id' }, function () {
          this.route('table');
        });
        this.route('international-competitor', { path: '/international-competitors/:competitor_id' });
        // this.resource('hall', {path: '/halls/:hall_id'});
        this.route('data', function () {
          this.route('entity', { path: '/:entity_id' }, function () {
            this.route('device', { path: '/:device_id' });
          });
        });
        this.route('halls');
        this.route('organisation_users', { path: '/users' });
        this.route('tips');
      });
      this.route('bad_url', { path: '/*badurl' }); // Catch everything else!
    });
    this.route('bare', { path: '/iframe' }, function () {
      this.route('competition', { path: '/competition/:competition_id' });
      this.route('competitor', { path: '/competitor/:competitor_id' });
      this.route('international-competition', { path: '/international/:ic_id' });
    });
    this.route('widgets', function () {
      this.route('fullscreen', { path: '/fullscreen/competition/:competition_id' });
    });
  });

  exports.default = Router;
});