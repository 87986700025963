define('saves-client/pods/components/performance-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "table",
    classNames: ['performances'],
    competitor: null,
    international: false,
    editable: false,

    performances: Ember.computed('international', 'competitor.performances', function () {
      var ic = this.get('international');
      var competitor = this.get('competitor');
      var performances = competitor.get('performances');
      if (!ic) {
        return performances;
      } else {
        var start_date = ic.get('start_date');
        var end_date = ic.get('end_date');
        return performances.filter(function (item) {
          return item.get('start_date') >= start_date && item.get('end_date') <= end_date;
        }, this);
      }
    }),

    target: function () {
      var performances = this.get('performances');
      return performances.reduce(function (previousValue, performance) {
        if (performance.get('include_in_cumulative')) {
          return previousValue + performance.get("target");
        } else {
          return previousValue;
        }
      }, 0);
    }.property('performances.@each.target'),

    adjustment: function () {
      var performances = this.get('performances');
      return performances.reduce(function (previousValue, performance) {
        if (performance.get('include_in_cumulative')) {
          return previousValue + parseFloat(performance.get("adjustment")); // the input field provides text - not ideal
        } else {
          return previousValue;
        }
      }, 0);
    }.property('performances.@each.adjustment'),

    adjusted_target: function () {
      var performances = this.get('performances');
      return performances.reduce(function (previousValue, performance) {
        if (performance.get('include_in_cumulative')) {
          return previousValue + performance.get("adjusted_target");
        } else {
          return previousValue;
        }
      }, 0);
    }.property('performances.@each.adjusted_target'),

    actual: function () {
      var performances = this.get('performances');
      return performances.reduce(function (previousValue, performance) {
        if (performance.get('include_in_cumulative')) {
          return previousValue + performance.get("actual");
        } else {
          return previousValue;
        }
      }, 0);
    }.property('performances.@each.actual'),

    cumulative_savings: function () {
      var performances = this.get('performances');
      var data = performances.reduce(function (previousValue, performance) {
        if (performance.get('include_in_cumulative')) {
          var actual = performance.get("actual");
          var target = performance.get("adjusted_target");
          return {
            target: previousValue.target + target,
            actual: previousValue.actual + actual
          };
        } else {
          return previousValue;
        }
      }, { target: 0, actual: 0 });
      if (data.target && data.actual) {
        return (data.target - data.actual) / data.target * 100;
      } else {
        return null;
      }
    }.property('performances.@each.adjusted_target', 'performances.@each.actual'),

    actions: {
      toggleEditable: function () {
        var editable = this.get('editable');
        if (editable) {
          //save it all
          var performances = this.get('performances');
          var changed = false;
          performances.forEach(function (performance) {
            if (performance.get('hasDirtyAttributes')) {
              performance.save();
              changed = true;
            }
          });
          if (changed) {
            this.get('competitor').reload().then(function (model) {
              console.log("reloaded");
              console.log(model);
            });
          }
        }
        this.toggleProperty('editable');
      }
    }

  });
});