define('saves-client/pods/components/slug-preview/component', ['exports', 'saves-client/utils/slug'], function (exports, _slug) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    str: null,
    slug: Ember.computed('str', function () {
      return (0, _slug.default)(this.get('str'));
    })
  });
});