define('saves-client/pods/normal/international/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    account: Ember.inject.service(),
    authenticationRoute: 'normal.login',
    beforeModel: function () {
      if (!this.get('account.administrator')) {
        this.transitionTo('normal.index');
      }
    },
    model: function () {
      return this.store.findAll('international_competition');
    }
  });
});