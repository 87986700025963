define('saves-client/pods/normal/organisation/data/entity/device/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authenticationRoute: 'normal.login',
    model: function (params) {
      return this.store.findRecord('device', params.device_id);
    } //,
    // setupController: function (controller, model) {
    //   // Call _super for default behavior
    //   this._super(controller, model);
    //   // Implement your custom setup after
    //   //this.controllerFor('application').set('showingPhotos', true);
    //   controller.set('organisation', this.modelFor('organisation'));
    // }
  });
});