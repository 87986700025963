define('saves-client/pods/normal/organisation/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    account: Ember.inject.service(),
    me: Ember.computed.alias('account.currentUser'),
    security: function () {
      if (!this.get('session.isAuthenticated')) {
        return { isAllowed: false, notes: "Not logged in..." };
      }
      var me = this.get('account.currentUser');

      //TODO: this is wrong - me is a promise and may not be fullfilled.
      if (me.get('administrator')) {
        return { isAllowed: true, notes: "Administrators are always allowed" };
      }

      var user_id = me.get('id');

      //list of users allowed to edit this org
      var user_promises = this.get('model.organisation_users').mapBy('user'); //a list of promises
      var promise = Ember.RSVP.Promise.all(user_promises).then(function (users) {
        var user_id_promises = users.mapBy('id'); //a list of promises
        return Ember.RSVP.Promise.all(user_id_promises).then(function (user_ids) {
          //promises are resolved into actual user ids
          var allowed = user_ids.any(function (authorised_id) {
            return authorised_id === user_id;
          });
          return { isAllowed: allowed, notes: allowed ? "Access approved for this org only" : "Access denied" };
        });
      });

      return Ember.ObjectProxy.extend(Ember.PromiseProxyMixin).create({
        promise: promise
      });
    }.property('model', 'account.currentUser'),
    otherCompetitions: function () {
      return this.get('competitions');
    }.property('competitions'),
    actions: {
      reload: function () {
        this.set("logoRefresh", +new Date());
        this.set('logoProgress', 0);
        this.set('editingLogo', false);
        this.get('model').reload();
      },
      ToggleEditLogo: function () {
        this.set('editingLogo', !this.get('editingLogo'));
      },
      ToggleShowInfo: function () {
        var showinfo = this.get('showInfo');
        if (showinfo) {
          this.set('editingInfo', false);
        }
        this.set('showInfo', !showinfo);
      },
      cancelOrg: function () {
        var model = this.get('model');
        if (model.get('isNew')) {
          model.deleteRecord();
          this.transitionToRoute('index');
        }
      },
      save: function () {
        this.set('editingLogo', false);
        if (this.get('model.isNew')) {
          this.set('model.id', this.get('model.fullname'));
        }
        return true;
      },
      setMainCompetition: function (competition) {
        this.get('model.competitions').forEach(function (comp) {
          comp.set('main', false);
        });
        competition.set('main', true);
        competition.save();
        var organisation = this.get('model');
        organisation.set("main_competition", competition);
      }
    }
  });
});