define('saves-client/pods/components/hall-entity-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['hall-entity'],
    hall_entity: null,
    editable: false,
    actions: {
      delete: function () {
        this.sendAction("delete", this.get('hall_entity'));
      },
      save: function () {
        var _this = this;
        this.get('hall_entity').save().then(function () {
          _this.set('editable', false);
        });
      },
      toggleEditable: function () {
        this.toggleProperty('editable');
      },
      cancel: function () {
        this.set('editable', false);
        this.get('hall_entity').rollbackAttributes();
      }
    }
  });
});