define('saves-client/pods/components/international-competitor-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['baseline_status', 'uncalculated', 'hidden:unpublished'],
    ceiling: 50,
    baseline_status: function () {
      var competitor = this.get('competitor');
      if (!competitor.get('baseline_validated')) {
        return 'invalid';
      }
    }.property('competitor.baseline_validated'),
    uncalculated: function () {
      return !this.get('competitor.calculated');
    }.property('competitor.calculated'),
    hidden: function () {
      return this.get('competitor.hidden');
    }.property('competitor.hidden'),
    actions: {
      toggleHidden: function () {
        var competitor = this.get("competitor");
        competitor.toggleProperty('hidden');
        competitor.save();
        // this.sendAction('toggleHidden');
      },
      delete: function () {
        var competitor = this.get("competitor");
        if (confirm("Are you sure you want to remove '" + competitor.get('hall.name') + "'?")) {
          competitor.deleteRecord();
          competitor.save();
        }
      },
      save: function () {
        this.get('competitor').save();
      },
      reload: function () {
        this.get('competitor').reload();
      }
    }
  });
});