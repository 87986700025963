define('saves-client/models/competition', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    organisation: _emberData.default.belongsTo('organisation', { inverse: 'competitions', 'async': true }),
    international_registrant: _emberData.default.belongsTo('international_registrant', { 'async': true }),
    name: _emberData.default.attr('string'),
    start_date: _emberData.default.attr('isodate'),
    end_date: _emberData.default.attr('isodate'),
    default_baseline_start: _emberData.default.attr('isodate'),
    default_baseline_end: _emberData.default.attr('isodate'),
    default_baseline_model: _emberData.default.attr('string'),
    main: _emberData.default.attr('boolean'),
    published: _emberData.default.attr('boolean'),
    neutralised: _emberData.default.attr('boolean', { defaultValue: false }),
    show_aggregated: _emberData.default.attr('boolean', { defaultValue: false }),
    competitors: _emberData.default.hasMany('competitor', { 'async': true }),
    msg_title: _emberData.default.attr('string'),
    msg_body: _emberData.default.attr('string'),
    calculated: _emberData.default.attr('boolean'),
    actual: _emberData.default.attr('number'),
    target: _emberData.default.attr('number'),
    savings: Ember.computed('target', 'actual', function () {
      let target = this.get('target');
      let actual = this.get('actual');
      return target - actual;
    }),
    percent_savings: Ember.computed('target', 'savings', function () {
      let target = this.get('target');
      let savings = this.get('savings');
      return Math.round(savings / target * 1000) / 10;
    }),
    inFuture: function () {
      var start_date = moment.utc(this.get('start_date'));
      return start_date.isAfter();
    }.property('start_date'),
    inPast: function () {
      var end_date = moment.utc(this.get('end_date'));
      return end_date.isBefore();
    }.property('end_date'),
    isCurrent: function () {
      var start_date = moment.utc(this.get('start_date'));
      var end_date = moment.utc(this.get('end_date'));
      return start_date.isBefore() && end_date.isAfter();
    }.property('start_date', 'end_date')
  });
});