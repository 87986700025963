define('saves-client/pods/normal/new-org/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function () {
      return this.store.createRecord('organisation');
    },
    actions: {
      willTransition: function (transition) {
        var new_org = this.controller.get('model');
        if (new_org.get('hasDirtyAttributes')) {
          if (!new_org.get('fullname') || confirm("Discard new university '" + this.controller.get('model.fullname') + "'?")) {
            new_org.deleteRecord();
            return true;
          } else {
            transition.abort();
          }
        } else {
          return true;
        }
      }
    }
  });
});