define('saves-client/pods/normal/organisation/competitor/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    account: Ember.inject.service(),
    competitor: Ember.inject.controller('normal.organisation.competitor')
  });
});