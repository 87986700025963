define('saves-client/pods/components/competitor-editor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    competitor: null,
    open: false,
    actions: {
      toggleOpen() {
        this.toggleProperty('open');
      },
      setModel(model) {
        this.set('competitor.baseline_model', model);
        this.set('open', false);
      }
    }
  });
});