define('saves-client/pods/components/international-league-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['international-league-table'],
    ic: null,
    applyLimit: true,
    visibleCompetitors: Ember.computed.filterBy('ic.competitors', 'hidden', false),
    sortProperties: ['ic_has_data:desc', 'ic_savings:desc'],
    sortedCompetitors: Ember.computed.sort('visibleCompetitors', 'sortProperties'),
    topTen: Ember.computed.sort('ic.top_ten', 'sortProperties'),
    myCompetitors: Ember.computed('topten', 'sortedCompetitors', 'applyLimit', function () {
      let props = this.getProperties('topTen', 'sortedCompetitors', 'applyLimit');
      return props.applyLimit ? props.topTen : props.sortedCompetitors;
    }),
    actions: {
      toggleLimit: function () {
        this.toggleProperty('applyLimit');
      }
    }
  });
});