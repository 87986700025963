define('saves-client/pods/components/savings-bar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var savingToWidth = function (saving, domain, range) {
    return (1 - Math.abs(saving) / domain) * range;
  };

  exports.default = Ember.Component.extend({
    tagName: "div",
    ceiling: 20,
    classNames: ['savings-bar'],
    classNameBindings: ['isNegative:neg:pos', 'isMax:max'],
    neutralised: false,
    international: false,
    savings: Ember.computed('competitor.savings', 'competitor.ic_savings', 'international', function () {
      if (this.get('international')) {
        return this.get('competitor.ic_savings');
      } else {
        return this.get('competitor.savings');
      }
    }),
    savings_date: Ember.computed('international', 'competitor.ic_savings_date', 'competitor.savings_date', function () {
      if (this.get('international')) {
        return this.get('competitor.ic_savings_date');
      } else {
        return this.get('competitor.savings_date');
      }
    }),
    old_savings_date: function () {
      var competitor = this.get('competitor');
      var international = this.get('international');
      if (international) {
        return competitor.get('ic_savings_date');
      } else {
        return competitor.get('savings_date');
      }
    }.property('competitor.savings_date', 'international'),
    absolute_savings: function () {
      return Math.abs(this.get('savings'));
    }.property('savings'),
    isNegative: function () {
      var savings = this.get('savings');
      return savings < 0;
    }.property('savings'),
    isMax: function () {
      var savings = this.get('savings');
      return Math.abs(savings) > this.get('ceiling');
    }.property('savings'),
    width: function () {
      var savings = this.get('savings');
      return savingToWidth(savings, this.get('ceiling'), 50);
    }.property('savings'),
    computedStyle: Ember.computed('width', function () {
      var width = this.get('width');
      if (width > 0) {
        // return new Ember.Handlebars.SafeString(`width: ${width}%;`);
        return Ember.String.htmlSafe(`width: ${width}%;`);
      }
    })
  });
});