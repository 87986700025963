define('saves-client/pods/normal/users/user/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function (params) {
      return this.store.findRecord('user', params.username);
    },
    actions: {
      save: function () {
        var model = this.modelFor('normal.users.user');
        model.save();
      },
      delete: function () {
        var self = this;
        var model = this.modelFor('normal.users.user');
        model.one('didDelete', this, function () {
          self.transitionTo('users');
        });
        if (confirm("Are you sure you want to delete '" + model.get('fullname') + "'?")) {
          model.deleteRecord();
          model.save();
        }
      }
    }
  });
});