define('saves-client/pods/normal/international/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      setLiveIC: function (ic) {
        if (ic.get('live')) {
          ic.set('live', false);
        } else {
          var ics = this.get('model');
          ics.forEach(function (item) {
            item.set('live', false);
          });
          ic.set('live', true);
        }
        ic.save();
      },
      setOpenIC: function (ic) {
        if (ic.get('open')) {
          ic.set('open', false);
        } else {
          if (ic.get('locked')) {
            alert("Cannot open a locked competition!\n\nUnlock first.");
            return;
          } else {
            var ics = this.get('model');
            ics.forEach(function (item) {
              item.set('open', false);
            });
            ic.set('open', true);
          }
        }
        ic.save();
      }
    }
  });
});