define('saves-client/pods/components/site-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'header',
    attributeBindings: ['role'],
    role: 'banner',
    classNames: ["site-header"],
    has_international: false
  });
});