define('saves-client/pods/components/hall-droppable/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    imageProgress: 0,
    imageRefresh: +new Date(),
    tagName: 'li',
    classNames: ['droppable', 'editable'],
    classNameBindings: ['editing:highlighted'],
    attributeBindings: ['droppable', 'draggable'],
    droppable: "true",
    editing: false,
    dragOver: function (event) {
      event.preventDefault();
    },
    drop: function (event) {
      var hall = this.get('hall');
      var entityId = event.dataTransfer.getData('text/data');
      this.sendAction('createHallEntity', hall, entityId);
    },
    keyPress: function (event) {
      if (event.keyCode === 13) {
        this.get('controller').send('save');
      }
    },
    imageUrl: function () {
      if (this.get('hall.has_image')) {
        return this.get('hall.imageEndPoint') + "?" + this.get('imageRefresh');
      } else {
        return "/assets/images/default-hall.png";
      }
    }.property('hall.imageEndPoint', 'hall.has_image', 'imageRefresh'),
    actions: {
      toggleEditable: function () {
        this.set('editing', !this.get('editing'));
      },
      save: function () {
        this.set('editing', false);
        var hall = this.get('hall');
        hall.save().then(function () {
          console.log("save complete");
        });
      },
      deleteHall: function () {
        var hall = this.get('hall');
        if (confirm("Are you sure you want to delete '" + hall.get('name') + "'?\nThis will also remove it from all attached competitions?")) {
          hall.deleteRecord();
          hall.save();
        }
      },
      // deleteHall: function () {
      //   this.sendAction('deleteHall');
      // },
      // deleteHallEntity: function (hall_entity) {
      //   this.sendAction('deleteHallEntity', hall_entity);
      // },
      deleteHallEntity: function (hall_entity) {
        var hall = this.get('hall');
        hall_entity.destroyRecord().then(function (dead_record) {
          hall.get('hall_entities').removeObject(dead_record);
        });
      },
      reload: function () {
        this.set("imageRefresh", +new Date());
        this.set('imageProgress', 0);
        this.set('editing', false);
        var hall = this.get('hall');
        hall.set('has_image', true);
      }
    }
  });
});