define('saves-client/pods/components/competitions-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['angle-list'],
    classNameBindings: ['hideList:display-none'],
    isAllowed: false, // true if is allowed to edit and stuff
    draggable: false,
    competitions: null,
    publishedCompetitions: Ember.computed.filterBy('competitions', 'published', true),
    publishedCompetitionCount: Ember.computed.alias('publishedCompetitions.length'),
    hideList: Ember.computed.lte('publishedCompetitionCount', 1),
    route: null,
    actions: {
      setMainCompetition: function (competition) {
        this.sendAction('setMainCompetition', competition);
      }
    }
  });
});