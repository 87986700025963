define('saves-client/pods/bare/international-competition/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function (params) {
      return this.store.findRecord('international_competition', params.ic_id);
    },
    setupController: function (controller, model) {
      // Call _super for default behavior
      this._super(controller, model);
      // Implement your custom setup after
      this.store.query('competitor', { international_competition: model.get('id') }).then(function (result) {
        controller.set('competitors', result);
      });
    }
  });
});