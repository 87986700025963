define('saves-client/pods/components/org-details/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "div",
    logoProgress: 0,
    logoRefresh: +new Date(),
    classNames: ['org-details', 'editable'],
    org: null,
    actions: {
      save: function () {
        this.set('editName', false);
        this.set('editInfo', false);
        if (this.get('org.isNew')) {
          this.set('org.id', this.get('org.fullname'));
        }
        let org = this.get('org');
        org.save();
        // this.sendAction('save');
      },
      reload: function () {
        this.set("logoRefresh", +new Date());
        this.set('logoProgress', 0);
        this.get('org').reload();
        this.sendAction('refresh');
      }
    }
  });
});