define('saves-client/models/device', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    entity: _emberData.default.belongsTo('entity'),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    start_date: _emberData.default.attr('isodate'),
    end_date: _emberData.default.attr('isodate'),
    measurement_count: _emberData.default.attr('number'),
    measurements: _emberData.default.hasMany('measurement'), //, {async: true}),
    average_resolution: function () {
      //get data
      var start_date = moment.utc(this.get('start_date'));
      var end_date = moment.utc(this.get('end_date'));
      var measurement_count = this.get('measurement_count');
      //calculate average milliseconds per reading and load into a duration
      var average = end_date.diff(start_date) / measurement_count;
      var duration = moment.duration(average);
      return duration.humanize();
    }.property('start_date', 'end_date', 'measurement_count'),
    measurements_per_week: function () {
      //get data
      var start_date = moment.utc(this.get('start_date'));
      var end_date = moment.utc(this.get('end_date'));
      var measurement_count = this.get('measurement_count');
      //calculate average readings per week
      var weeks = end_date.diff(start_date, 'weeks', true);
      console.log(weeks);
      console.log(end_date.diff(start_date, 'days', true));
      var average = measurement_count / weeks;
      return average.toFixed(2);
    }.property('start_date', 'end_date', 'measurement_count')
  });
});