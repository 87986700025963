define('saves-client/pods/components/competition-editor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "article",
    classNames: ['droppable', 'competition-editor'],
    options: false,
    message: false,
    competition: null,
    sortProperties: ['savings:desc'],
    sortedCompetitors: Ember.computed.sort('competition.competitors', 'sortProperties'),
    has_changes: Ember.computed.alias('competition.hasDirtyAttributes'),
    dragOver: function (event) {
      event.preventDefault();
    },
    drop: function (event) {
      var hall_id = event.dataTransfer.getData('text/data');
      this.sendAction('attachHall', hall_id);
    },
    has_message: Ember.computed.bool('competition.msg_title'),
    has_message_body: Ember.computed.bool('competition.msg_body'),
    no_message: Ember.computed.not('has_message'),
    actions: {
      save: function () {
        this.sendAction('save');
      },
      delete: function () {
        this.sendAction('delete');
      },
      reload: function () {
        var competition = this.get('competition');
        competition.get('competitors').reload();
      },
      recalculate: function () {
        this.get('competition').reload();
        var competitors_promise = this.get('competition.competitors');
        competitors_promise.then(function (competitors) {
          competitors.forEach(function (competitor) {
            competitor.set('calculated', false);
            competitor.save();
          });
        });
      },
      applyToAll: function () {
        var competitors_promise = this.get('competition.competitors');
        var default_baseline_start = this.get('competition.default_baseline_start');
        var default_baseline_end = this.get('competition.default_baseline_end');
        competitors_promise.then(function (competitors) {
          competitors.forEach(function (competitor) {
            competitor.set('baseline_start_date', default_baseline_start);
            competitor.set('baseline_end_date', default_baseline_end);
            competitor.save();
          });
        });
      },
      applyModelToAll() {
        var competitors_promise = this.get('competition.competitors');
        var default_baseline_model = this.get('competition.default_baseline_model');
        console.log(default_baseline_model);
        competitors_promise.then(function (competitors) {
          competitors.forEach(function (competitor) {
            competitor.set('baseline_model', default_baseline_model);
            console.log(competitor.get('baseline_model'));
            competitor.save();
          });
        });
      },
      toggleOptions() {
        this.toggleProperty('options');
      },
      toggleMessage() {
        if (this.get('message') && this.get('has_changes')) {
          this.get('competition').save();
        }
        this.toggleProperty('message');
      },
      setModel(model) {
        this.set('competition.default_baseline_model', model);
      },
      clear_message() {
        this.set("competition.msg_title", "");
        this.set("competition.msg_body", "");
      }
    }
  });
});