define('saves-client/pods/components/organisation-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ["editable", "organisation-item"],
    organisation: null,
    editable: null,
    editing: false,
    logoRefresh: +new Date(),
    actions: {
      togglePublished: function () {
        var organisation = this.get("organisation");
        organisation.toggleProperty('published');
        organisation.save();
      },
      toggleDisabled: function () {
        var organisation = this.get("organisation");
        organisation.toggleProperty('disabled');
        organisation.save();
      },
      toggleEditing() {
        this.set('logoRefresh', +new Date());
        this.toggleProperty('editing');
      },
      undo() {
        this.get("organisation").rollbackAttributes();
        this.set('editing', false);
      },
      save() {
        this.get("organisation").save();
        this.set('editing', false);
      },
      closed() {
        let org = this.get("organisation");
        if (org.get('hasDirtyAttributes')) {
          if (confirm("You have made changes, do you want to keep them?")) {
            this.send('save');
          } else {
            this.send('undo');
          }
        } else {
          this.send('toggleEditing');
        }
      },
      refresh() {
        this.set('logoRefresh', +new Date());
      }
    }
  });
});