define('saves-client/pods/normal/organisation/data/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    account: Ember.inject.service(),
    organisation_controller: Ember.inject.controller('normal.organisation'),
    organisation: Ember.computed.alias('organisation_controller.model'),
    uploadProgress: 0,
    actions: {
      fileUploaded: function () {
        this.set('uploadProgress', 0);
        var org = this.get('organisation');
        org.reload().then(function (new_org) {
          new_org.get('files').reload();
          new_org.get('entities').reload();
        });
      },
      reloadFiles: function () {
        var org = this.get('organisation');
        org.reload().then(function (new_org) {
          new_org.get('files').reload();
        });
      }
    }
  });
});