define('saves-client/models/hall', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    orgname: _emberData.default.belongsTo('organisation'),
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    // competitors: DS.hasMany('competitor', {async: true}),
    hall_entities: _emberData.default.hasMany('hallEntity', { async: true }),
    short_data: _emberData.default.attr('boolean', { defaultValue: true }),
    has_image: _emberData.default.attr('boolean'),
    /* TODO; see image checking App.Organisation */
    imageEndPoint: function () {
      var adapter = Ember.getOwner(this).lookup('adapter:application');
      return adapter.buildURL('hall', this.get('id')) + "/image";
    }.property('id'),
    imageUrl: function () {
      if (this.get('has_image')) {
        return this.get('imageEndPoint');
      } else {
        return "/assets/images/default-hall.png";
      }
    }.property('imageEndPoint', 'has_image')
  });
});