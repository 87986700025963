define('saves-client/pods/components/org-editor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "div",
    classNames: ['org-editor'],
    customer_groups: null,
    org: null,
    actions: {
      set_group(group) {
        let org = this.get('org');
        org.set('customer_group', group);
        org.save();
      }
    }
  });
});