define("saves-client/models/organisation", ["exports", "ember-data", "saves-client/utils/slug"], function (exports, _emberData, _slug) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    orgname: _emberData.default.attr('string'),
    fullname: _emberData.default.attr('string'),
    description: _emberData.default.attr('string', { defaultValue: '' }),
    halls: _emberData.default.hasMany('hall', { async: true }),
    entities: _emberData.default.hasMany('entity', { async: true }),
    competitions: _emberData.default.hasMany('competition', { async: true }),
    has_logo: _emberData.default.attr('boolean', { defaultValue: false }),
    main_competition: _emberData.default.belongsTo('competition', { async: true }),
    organisation_users: _emberData.default.hasMany('organisation_user', { async: true }),
    published: _emberData.default.attr('boolean'),
    disabled: _emberData.default.attr('boolean', { defaultValue: false }),
    files: _emberData.default.hasMany('file', { async: true }),
    customer_group: _emberData.default.belongsTo('customer_group', { async: true }),
    tips: _emberData.default.hasMany('tip', { async: true }),
    logoEndPoint: function () {
      var adapter = Ember.getOwner(this).lookup('adapter:application');
      return adapter.buildURL('organisation', this.get('id')) + "/logo";
    }.property('id'),
    uploadUrl: function () {
      let adapter = Ember.getOwner(this).lookup('adapter:application');
      return adapter.buildURL('organisation', this.get('id')) + "/upload";
    }.property('id'),
    slug: function () {
      return (0, _slug.default)(this.get('fullname'));
    }.property('fullname'),
    url: function () {
      return location.protocol + '//' + location.hostname + "/" + this.get('slug'); //App.ApplicationAdapter.prototype.buildURL('organisation', this.get('slug'))
    }.property('slug')
  });
});