define('saves-client/services/account', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    organisation: null,
    currentUser: Ember.computed('session.data.authenticated.user_id', function () {
      const user_id = this.get('session.data.authenticated.user_id');
      if (!Ember.isEmpty(user_id)) {
        return _emberData.default.PromiseObject.create({
          promise: this.get('store').find('user', user_id)
        });
      }
    }),
    organisation_users: Ember.computed.alias('organisation.organisation_users'),
    users: Ember.computed.mapBy('organisation_users', 'user'),
    user_ids: Ember.computed.mapBy('users', 'username'),
    is_org_user: Ember.computed('user_ids', 'session.data.authenticated.user_id', function () {
      return this.get('user_ids').includes(this.get('session.data.authenticated.user_id'));
    }),
    canEditOrg: Ember.computed('session.isAuthenticated', 'currentUser.administrator', 'is_org_user', function () {
      if (!this.get('session.isAuthenticated')) {
        return false;
      }
      if (this.get('currentUser.administrator')) {
        return true;
      }
      return this.get('is_org_user');
    }),
    administrator: Ember.computed.alias('currentUser.administrator')
  });
});