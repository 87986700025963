define('saves-client/pods/normal/organisation/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    account: Ember.inject.service(),
    model: function () {
      return this.modelFor('normal.organisation').get('main_competition');
    },
    setupController: function (controller, model) {
      this._super(controller, model);
      controller.set('organisation', this.modelFor('normal.organisation'));
    }
  });
});