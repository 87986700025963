define('saves-client/pods/normal/organisation/competitor/table/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    account: Ember.inject.service(),
    actions: {
      apply: function (stuff) {
        let from_date = moment.utc(stuff.from_date);
        let to_date = moment.utc(stuff.to_date);
        let performances = this.get('model.performances');
        performances.forEach(function (p) {
          if (p.get('start_date') >= from_date && p.get('start_date') <= to_date) {
            let adjustment = 0;
            if (stuff.use_percentage) {
              adjustment = Math.round(stuff.adjustment * p.get('target')) / 100;
            } else {
              adjustment = stuff.adjustment;
            }
            p.set('adjustment', adjustment);
          }
        });
      }
    }
  });
});