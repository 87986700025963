define('saves-client/pods/normal/organisation/halls/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    account: Ember.inject.service(),
    organisation: Ember.inject.controller('normal.organisation'),
    actions: {
      addhall: function () {
        var organisation = this.get('organisation');
        var newName = this.get('newName');
        var new_hall = this.store.createRecord('hall', { name: newName, organisation: organisation });
        organisation.get("halls").unshiftObject(new_hall);
        var self = this;
        new_hall.save().then(function () {
          self.set('newName', null);
        });
      },
      createHallEntity: function (hall, entityId) {
        var that = this;
        this.store.findRecord('entity', entityId).then(function (the_entity) {
          var new_hall_entity = that.store.createRecord('hall_entity', {
            hall: hall,
            entity: the_entity
          });
          new_hall_entity.save().then(function (saved_hall_entity) {
            //successfully added - add to list
            hall.get('hall_entities').pushObject(saved_hall_entity);
          }, function (obj) {
            alert("Server rejected request with the following message:\n\n" + obj.responseJSON.errors[0].description);
            new_hall_entity.deleteRecord();
          });
        });
      }
    }
  });
});